#home-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

#home-page > div {
  width: 90%;
}

.arrow-container {
  display: block;
}

.arrow-container svg {
  fill: grey;
}

.arrow-container.prev {
  transform: translateX(-3vw);
}
.arrow-container.next {
  transform: translateX(3vw);
}

.slick-slider {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  #home-page {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
    margin-top: 60px;
  }
}