.renderPreview {
  cursor: pointer;
  height: 400px;
  width: 400px;
  margin: 20px;
}

.renderPreview img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 600px) {
  .renderPreview {
    height: 200px;
    width: 200px;
    margin: auto;
  }
}